import {
	LazyLeadAdminPanel,
	LazyLeadStatusReport,
	LazyContentHubViewPage,
	LazyContentHubDetailsPage,
	LazyContentHubPage,
	LazyFormPage,
	LazyHome,
} from './pages';

export const ApplayoutRoutes = [
	{
		path: '/',
		element: <LazyHome />,
	},
	{
		path: '/contenthub',
		element: <LazyContentHubPage />,
		children: [
			{
				path: 'details/:productDivision',
				element: <LazyContentHubDetailsPage />,
				children: [
					{
						path: 'display/:fileName',
						element: <LazyContentHubViewPage />,
					},
				],
			},
		],
	},
	{
		path: '/form',
		element: <LazyFormPage />,
	},
	{
		path: '/home',
		element: <LazyHome />,
	},
	{
		path: '/leadreport',
		element: <LazyLeadStatusReport />,
	},
	{
		path: '/admin',
		element: <LazyLeadAdminPanel />,
	},
];
