import conditionsIcon from './conditions.svg';
import closeIcon from './close.svg';
import downloadIcon from './download.svg';
import errorIcon from './error.svg';
import formFooterLogo from './formFooterLogo.svg';
import homeBottomGiftIcon from './home_bottom_gift.svg';
import pdfIcon from './pdfIcon.svg';
import popupProgramLogo from './popupProgramLogo.svg';
import popupHexAgonsIcon from './popupHexAgons.svg';
import popupTrophyIcon from './popupTrophy.svg';
import popupIphoneIcon from './popupIphone.svg';
import popupChartIcon from './popupChart.svg';
import popupTableIcon from './popupTable.svg';
import popupChartJAIcon from './popupChartJA.svg';
import popupTableJAIcon from './popupTableJA.svg';
import popupChartKOJAIcon from './popupChartKO.svg';
import popupTableKOIcon from './popupTableKO.svg';
import popupQRCodeIcon from './QRCode.svg';
import popupQRCodeIconKlead from './popupQRCodeIconKlead.svg';
import programDetailsIcon from './program_detail.svg';
import successfulIcon from './successful.svg';
import videoIcon from './video.svg';
import viewIcon from './view.svg';
import imgIcon from './imgIcon.svg';
import newPdfIcon from './newPdfIcon.svg';
import wordIcon from './wordIcon.svg';
import rightArrow from './rightArrow.svg';
import pptxIcon from './pptxIcon.svg';
import leadStatusReportIcon from './leadStatusReportIcon.svg';
import geneAiIcon from './geneAiIcon.svg';
import adminIcon from './adminIcon.svg';
import editOutlined from './editOutlined.svg';
import eyeOutlined from './eyeOutlined.svg';
import editOutlinedDisabled from './editOutlinedDisabled.svg';
import tfsRedBG from './tfsRedBG.svg';
import APJIcon from './1APJIcon.svg';
import programPopupKorea from './programPopupKorea.svg';
import popupChartKoreaKlead from './popupChartKoreaKlead.svg';
import urlIcon from './link.svg';
export default {
	successfulIcon,
	programDetailsIcon,
	conditionsIcon,
	homeBottomGiftIcon,
	errorIcon,
	closeIcon,
	formFooterLogo,
	pdfIcon,
	popupProgramLogo,
	popupHexAgonsIcon,
	popupTrophyIcon,
	popupIphoneIcon,
	popupChartIcon,
	popupTableIcon,
	popupQRCodeIcon,
	popupChartJAIcon,
	popupTableJAIcon,
	popupChartKOJAIcon,
	popupTableKOIcon,
	downloadIcon,
	videoIcon,
	urlIcon,
	viewIcon,
	imgIcon,
	newPdfIcon,
	wordIcon,
	rightArrow,
	pptxIcon,
	leadStatusReportIcon,
	geneAiIcon,
	APJIcon,
	editOutlined,
	editOutlinedDisabled,
	eyeOutlined,
	tfsRedBG,
	adminIcon,
	programPopupKorea,
	popupChartKoreaKlead,
	popupQRCodeIconKlead,
};
