function getCookie(name: string) {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

const setCookie = (name: string, value: string, days?: number) => {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toUTCString();
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

const deleteCookie = (name: string) => {
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
};

const setScrollTop = (top?: number) => {
	window.scrollTo({
		top: top ? top : 0,
		left: 0,
		behavior: 'smooth',
	});
};

const regexAllSpace = new RegExp(/^\s+$/);

const formatDate = (date: any) => {
	/* For formatting in YYYY-MM-DD */
	const d = new Date(date);
	let month = '' + (d.getMonth() + 1);
	let day = '' + d.getDate();
	const year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
};

/* Seperating filename and file extension */
function separateExtensionFromFileName(filename: string): any {
	const lastIndexOfDot = filename.lastIndexOf('.');
	if (lastIndexOfDot === -1) {
		return filename;
	}
	return {
		name: filename.substring(0, lastIndexOfDot),
		type: filename.substring(lastIndexOfDot + 1, filename.length),
	};
}

/* Transform the camel case keys of tableData to sentence case */
function camelCaseToWords(s: string) {
	const result = s.replace(/([A-Z])/g, ' $1');
	return result.charAt(0).toUpperCase() + result.slice(1);
}

interface GroupedData {
	name: string;
	items: { title: string; files: any[] }[];
}

const processData = (data: any, userCountryCode: string, selectedLanguage?: any) => {
	const customGroupBy = (data: any, categoryKey: string, subCategoryKey: string): GroupedData[] => {
		const filteredData = data.filter(
			(item: any) =>
				(item.leadContentHubFiles.length > 1 ||
					(item.leadContentHubFiles.length === 1 &&
						item?.sharePointUrl &&
						item?.contentType == 'url')) &&
				(item.contentCountry === 'all' ||
					item.contentCountry.split(',').includes('all') ||
					item.contentCountry === userCountryCode ||
					item.contentCountry.split(',').includes(userCountryCode))
		);
		const groupedData: { [category: string]: { [subCategory: string]: any[] } } =
			filteredData.reduce((acc: any, item: any) => {
				const category = item[categoryKey];
				const subCategory = item[subCategoryKey];
				if (!acc[category]) {
					acc[category] = {};
				}
				if (!acc[category][subCategory]) {
					acc[category][subCategory] = [];
				}
				acc[category][subCategory].push(item);
				return acc;
			}, {});

		const remappedData = Object.keys(groupedData).map((category: any) => ({
			name: category,
			items: Object.keys(groupedData[category])
				.map((subCategory: any) => {
					return (
						groupedData[category][subCategory].length > 0 && {
							title: subCategory,
							files: groupedData[category][subCategory].map((item: any) => {
								item.pdfFileKey = item.leadContentHubFiles.find(
									(obj: any) => obj.fileType.toLowerCase() == 'pdf'
								)?.fileKey;

								item.thumbnailFileKey = item.leadContentHubFiles.find(
									(obj: any) =>
										obj.fileType.toLowerCase() == 'jpg' ||
										obj.fileType.toLowerCase() == 'jpeg' ||
										obj.fileType.toLowerCase() == 'png'
								)?.fileKey;

								item.thumbnailFileType = item.leadContentHubFiles.find(
									(obj: any) =>
										obj.fileType.toLowerCase() == 'jpg' ||
										obj.fileType.toLowerCase() == 'jpeg' ||
										obj.fileType.toLowerCase() == 'png'
								)?.fileType;
								if (item.thumbnailFileType?.toLowerCase() === 'jpg') {
									item.thumbnailFileType = 'jpeg';
								}

								item.otherFileKey = item.leadContentHubFiles.find(
									(obj: any) =>
										obj.fileType.toLowerCase() == 'pptx' ||
										obj.fileType.toLowerCase() == 'ppt' ||
										obj.fileType.toLowerCase() == 'mp4'
								)?.fileKey;

								return item;
							}),
						}
					);
				})
				.filter(Boolean)
				.sort((a: any, b: any) => {
					if (a.files[0]?.subcategorySequence && b.files[0]?.subcategorySequence) {
						return a.files[0].subcategorySequence - b.files[0].subcategorySequence;
					}
					return a.title.localeCompare(b.title);
				}),
		}));
		return remappedData.sort((a: any, b: any) => {
			return a.items[0].files[0].categorySequence - b.items[0].files[0].categorySequence;
		}) as GroupedData[];
	};
	const languageFilteredData = () => {
		if (!selectedLanguage) {
			return data;
		}
		return data.filter((item: any) => item.contentLanguage === selectedLanguage);
	};
	return customGroupBy(languageFilteredData(), 'contentCategory', 'contentSubcategory');
};

export {
	processData as processContentHubData,
	getCookie,
	setCookie,
	deleteCookie,
	setScrollTop,
	regexAllSpace,
	formatDate,
	separateExtensionFromFileName,
	camelCaseToWords,
};
